import { createRouter, createWebHashHistory } from 'vue-router'

import allCase_active from '@/assets/images/all-case-active.png'
import proList_active from '@/assets/images/proList-active.png'
import exam_active from '@/assets/images/exam-active.png'
import caseChat_active from '@/assets/images/caseChat-active.png'
import allCase from '@/assets/images/all-case1.png'
import proList from '@/assets/images/proList.png'
import exam from '@/assets/images/exam.png'
import caseChat from '@/assets/images/caseChat.png'
import per_active from '@/assets/images/群组 2@2x.png'
import per from '@/assets/images/群组 6@2x.png'
const routes = [

  {
    path: '/',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login')
  },
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HelloWorld.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register')
  },
  {
    path: '/getPassword',
    name: 'getPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/getPassword.vue')
  },
  {
    path: '/userSetttings',
    name: 'userSetttings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/userSetttings')
  },
  {
    path: '/casesInfo',
    name: 'casesInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/casesInfo'),
    
  },
  {
    path: '/2d', // 子路由的路径  
    name: '2d', // 子路由的名称  
    component: () => import('../views/casesInfo/2d'), // 子路由对应的组件，假设组件文件位于views/casesInfo目录下，文件名为2d.vue  
    meta: {
      breadcrumb: '2d'
    }
  },
  {
    path: '/3d', // 子路由的路径  
    name: '3d', // 子路由的名称  
    component: () => import('../views/3d/index.vue'), // 子路由对应的组件，假设组件文件位于views/casesInfo目录下，文件名为2d.vue  
    meta: {
      breadcrumb: '2d'
    }
  },
  {
    path: '/ConfirmPath', // 子路由的路径  
    name: 'ConfirmPath', // 子路由的名称  
    component: () => import('../views/AuditManagement/ConfirmPath') // 子路由对应的组件
  },
  {
    path: '/layout',
    name: 'layout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/layout'),
    children: [{
      path: "/AllCases",
      name: "AllCases",
      meta: {
        title: '全部病例',
        icon: allCase,
        active_icon: allCase_active
      },
      /** 在src/views 文件下创建home文件 */
      component: () => import("@/views/AllCases"),
    },
    {
      path: "/ProductionOrder",
      name: "ProductionOrder",
      meta: {
        title: '生产订单',
        icon: proList,
        active_icon: proList_active
      },
      /** 在src/views 文件下创建system文件 */
      component: () => import("@/views/ProductionOrder"),
    },
    {
      path: "/AuditManagement",
      name: "AuditManagement",
      meta: {
        title: '审核管理',
        icon: exam,
        active_icon: exam_active
      },
      component: () => import('@/views/AuditManagement')
    },
    {
      path: "/CaseDiscussion",
      name: "CaseDiscussion",
      meta: {
        title: '病例讨论',
        icon: caseChat,
        active_icon: caseChat_active
      },
      /** 在src/views 文件下创建productManagement文件 */
      component: () => import("@/views/CaseDiscussion"),
    },
    {
      path: "/docMana",
      name: "docMana",
      meta: {
        title: '医生管理',
        icon: per,
        active_icon: per_active
      },
      /** 在src/views 文件下创建productManagement文件 */
      component: () => import("@/views/docMana"),
    },
    ]

  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
