import request from "./request.js";
const allcases = {
    //获取全部病例
    getAllcases(params) {
        console.log('获取全部病例', params);

        return request({
            url: '/cases/list',
            method: 'post',
            data: params,
        })
    },
    //添加病例
    addCases(params) {
        return request({
            url: '/cases/add',
            method: 'post',
            data: params,
        })
    },
    //删除病例
    delCases(params) {
        return request({
            url: '/cases/del',
            method: 'post',
            data: params,
        })
    },
    //修改病例
    editCases(params) {
        return request({
            url: '/cases/edit',
            method: 'post',
            data: params,
        })
    },
    //添加标签
    addTag(params) {
        return request({
            url: '/cases/add_tag',
            method: 'post',
            data: params,
        })
    },

    //详情
    caseInfo(params) {
        return request({
            url: '/cases/show',
            method: 'post',
            data: params,
        })
    },
    //删除标签
    delTags(params) {
        return request({
            url: '/cases/del_tag',
            method: 'post',
            data: params,
        })
    }, sesstion_data(params) {
        return request({
            url: '/test/getJsonData',
            method: 'post',
            data: params,
        })
    },
    //文件上传分析
    upload_upanaly(params,config){
        return request({
            url: '/upload/upanaly',
            method: 'post',
            data: params,
            ...config
        })
    },
     //生成治疗记录3D分析
     record_buildSubaccess(params){
        return request(
            {
            url: '/record/buildSubaccess',
            method: 'post',
            data: params,
        }
    )
    }
}

export default allcases