
import axios from 'axios'
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus'
import store from '../store/index.js'
import { useRouter } from 'vue-router';
import router  from '@/router/index.js';
import { baseConfig } from "@/config/basicUrl.js"
const NETWORK_ERROR = '网络请求错误，请稍后重试...'


const service = axios.create({
    baseURL: baseConfig.url,//测试服
})

service.interceptors.request.use((req) => {
    //可以在请求之前做一些事情
    //比如自定义header， jwt-token等等
    return req
})

service.interceptors.response.use(
    response => {
        const res = response.data
        console.log('interceptors', res);

        // 对请求得到的响应做一些处理
        if (res.data.code === 200) {
            // 状态码是200表明请求正常，可以返回请求到的数据也可以做一些其他事情
            return response;
        } else {
            //'status code==401时重新登录

            if (res.status_code == 401 || res.data.code == 402) {
                router.push({
                    name: 'login'
                })
            }
            return response;
        }
    },
    error => {
        // 对响应错误做点什么
        // error.then(res => {
        //     console.log('有报错1111', res);
        // }).catch(err=>{
        console.log('有报错2222', router);
        if (error.response.data.status_code == 401) {
            Cookies.remove("access_token");
            router.go();
        }
        ElMessage.error(error.message);
        return error.response;
    })

let tokenRefresher = async () => {
    let now = new Date().getTime()
    if (now - Cookies.get('last_token_refresh_time') > 1000 * 60 * 4) {
        let res = await service({
            url: '/api/token/refresh/',
            method: 'post',
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`
            },
            data: {
                refresh: `${Cookies.get('refresh_token')}`
            }
        })
        if (res.status === 200) {
            store.commit('setAccessToken', res.data.access)
        } else if (res.status === 403) {
            // refresh token过期了，要求重新登录
            store.commit('clearRefreshToken')
            store.commit('clearAccessToken')
            router.push({
                name: 'login'
            })
        }
    }
}

function request(options) {
    console.log('options', options.url);
    options.method = options.method || 'get' // 如果没有传入method这个参数，就默认是get请求
    if (options.method.toLowerCase() === 'get') {
        // console.log(options)
        options.params = options.data
    }
    // 如果可以从cookie中获取到access_token，就添加到header中
    if (Cookies.get('access_token')) {
        // 设置token之前先检查是否需要更新token
        tokenRefresher()
        service.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('access_token')}`
    }
    return service(options)
}

export default request
